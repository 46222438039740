import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomizedSnackbars from "./notification.js";
import Format2 from "../tools/format2.js";
import EditIcon from "@mui/icons-material/Edit";

import {
  Grid,
  CircularProgress,
  Tooltip,
  Typography,
  Modal,
} from "@mui/material";
import OrderSkuEditor from "./orderSkuEditor.js";

const OrderSkuEditorModel = ({
  orderId,
  mappingProducts,
  setAliVariantName,
  showeditsuppliersku,
  setshoweditsuppliersku,
  handleCloseMapVariantModal,
  count3,
  setCount3,
  setShopifySku,
  allOrders,
  index,
  indexM,
  setIndexm,
  fetchOrders
}) => {
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState({ id: "", sku: "" });
  const [storeid, setStoreid] = useState("");
  const [sku, setSKU] = useState("");
  const [open, setOpen] = useState({ open: false, message: "" });
  const [count, setCount] = useState(0);
  const products_per_page = useSelector((state) => state.products_per_page);
  const loading_products = useSelector((state) => state.loading_products);
  const store = useSelector((state) => state.store);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen({ open: false, message: "" });
  };




  return (
    <Modal
      open={showeditsuppliersku}
      onClose={handleCloseMapVariantModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        mt: 6,
      }}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "80vh" }}
      >
        {loading_products ? (
          <CircularProgress />
        ) : (
          mappingProducts &&
          mappingProducts.length !== 0 &&
          mappingProducts.map((product) => {
            return (
              <OrderSkuEditor
                orderId={orderId}
                product_old={product}
                setOpen={setOpen}
                setCount={setCount}
                count={count}
                count3={count3}
                page_name="order"
                setCount3={setCount3}
                setAliVariantName={setAliVariantName}
                handleCloseMapVariantModal={handleCloseMapVariantModal}
                setshoweditsuppliersku={setshoweditsuppliersku}
                setShopifySku={setShopifySku}
                allOrders={allOrders}
                index={index}
                indexM={indexM}
                setIndexm={setIndexm}
                fetchOrders = {fetchOrders}
              />

            );
          })
        )}
      </Grid>

    </Modal>
  );
};
export default OrderSkuEditorModel;
